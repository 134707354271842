<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				data: {},
				PopularBlog: {},
			}
		},
		computed: {
			mySlug() {
				return this.$route.params.slug
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 300)
			this.getApi()
		},
		methods: {
			getApi() {
				Gen.apirest("/detail-blog/" + this.mySlug, {}, (err, resp) => {
					if (err) console.log(err)
					if (resp.code == 404) return this.$router.push({name:'404'})
					this.data = resp.data
					this.PopularBlog = resp.popular_blog
				})
			},
			shareFB() {
				window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(this.currentUrl),
					'fbShareWindow', 'top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) +
					', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
			},
			shareTW() {
				window.open("https://twitter.com/intent/tweet?text=" + encodeURI(this.data['apb_title_'+this.hl] + " -") + "&url=" +
					encodeURI(this.currentUrl), "", "modal=yes")
			},
		},
		watch: {
			$route() {
				this.getApi()
			}
		}
	};
</script>
<template>
	<Component404 v-if="page404"></Component404>
	<section v-else id="content" style="background-color: #000; overflow: visible">
		<div class="content-wrap pt-0 pb-0">
			<div id="section-about" class="section page-section bg_0f181b nobottommargin notopmargin clearfix">
				<div class="container clearfix">
					<div class="row">
						<div class="col-md-12">
							<ol class="breadcrumb">
								<li class="breadcrumb-item">
									<router-link :to="{name:'Home'}">
										{{ web.mn_home }}
									</router-link>
								</li>
								<li class="breadcrumb-item">
									<router-link :to="{name:'Blog'}">
										{{ web.mn_blog }}
									</router-link>
								</li>
								<li class="breadcrumb-item active" aria-current="page">{{ (data['apb_title_'+hl]||'-') }}</li>
							</ol>
						</div>
					</div>
					<div class="row">
						<div class="col-md-9">
							<div class="row">
								<div class="col-md-12">
									<div class="detail_post_content">
										<h1>{{ data['apb_title_'+hl] }}</h1>
										<ul>
											<li>{{ (data.apb_publish_date||"").dates("format") }}</li>
											<li>{{ data.apb_author }}</li>
										</ul>
									</div>
								</div>
								<div class="col-md-1 mt-1">
									<div class="detail_post_content">
										<ul>
											<li>{{web.lbl_share}}</li>
										</ul>
									</div>
								</div>
								<div class="col-md-5 mb-0 social-icons">
									<a href="javascript:;" @click="shareFB"
										class="social-icon si-dark  si-small si-borderless si-rounded si-facebook">
										<i class="icon-facebook"></i>
										<i class="icon-facebook"></i>
									</a>
									<a href="javascript:;" @click="shareTW"
										class="social-icon si-small si-dark si-borderless  si-rounded si-twitter">
										<i class="icon-twitter"></i>
										<i class="icon-twitter"></i>
									</a>
								</div>
							</div>
							<div class="wrap_detail_news">
								<div class="detail_news_thumb">
									<img :src="uploader(data.apb_image)" :alt="data['apb_title_'+hl]" :title="data['apb_title_'+hl]">
								</div>
							</div>
							<div class="entry_content_post">
								<p v-html="data['apb_desc_'+hl]"></p>
							</div>
						</div>
						<div class="col-md-3">
							<aside class="post_recent mtop_120">
								<h3>{{ web.lbl_popular_posts }}</h3>
								<ul class="">
									<li v-for="(v,k) in PopularBlog" :key="k">
										<router-link :to="{name:'DetailBlog', params:{slug:v.slug}}">
											{{ v.title }}
										</router-link>
									</li>
								</ul>
							</aside>
						</div>
					</div>
				</div>
			</div>
			<div class="clear"></div>
		</div>
	</section>
</template>